@font-face {
  font-family: ArialBold;
  src: url("./assets/fonts/ARLRDBD.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-repeat: no-repeat;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div,
body {
  font-family: ArialBold !important;
  font-weight: 700;
  /* transform: translate('-50%, -50%'); */
}

body {
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1a1a1a;
}
a {
  text-decoration: none;
  color: inherit;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
